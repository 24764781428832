<template>
  <div>
    <div class="content" style="padding-bottom: 60px">
      <div class="materialShop_order">
        <div class="materialShop_address">
          <a-row type="flex" justify="space-between">
            <a-col :span="3">
              <div class="materialShop_address_title">收货人信息</div>
            </a-col>
            <a-col :span="3">
              <a-button
                type="link"
                @click="$refs.addressForm.createModel()"
                class="materialShop_address_add"
              >新增收货地址</a-button
              >
            </a-col>
          </a-row>
          <a-row>
            <a-row>
              <a-col :span="12" v-for="item in addressList" :key="item.addressId" @click="getAddress(item)">
                <div
                  class="materialShop_address_item"
                  :class="[item.addressId == address.addressId ? 'active_materialShop_address_item' : '']"
                >
                  <a-row type="flex" justify="center">
                    <a-col :span="5" class="text--overflow" :title="item.consignee">{{ item.consignee }}</a-col>
                    <a-col
                      :span="9"
                      class="text--overflow"
                      :title="`${item.province}${item.city}${item.address}`"
                    >{{ item.province }}{{ item.city }}{{ item.county }}{{ item.address }}</a-col
                    >
                    <a-col :span="5" class="text--overflow">{{ item.phone }}</a-col>
                  </a-row>
                </div>
              </a-col>
            </a-row>
          </a-row>
          <div class="materialShop_address_more" @click="getMore">{{ text }}</div>
        </div>
        <div class="materialShop_time">
          <!-- <a-row>
            <a-col :span="2">期望收货时间</a-col>
            <a-col :offset="1" :span="12">
              <a-date-picker
                v-model="deliveryTime"
                @change="onChange"
                :disabled-date="disabledDate"
                style="width: 300px"
              />
            </a-col>
          </a-row> -->
          <a-row style="margin-top: 25px">
            <a-col :span="2">订单备注</a-col>
            <a-col :offset="1" :span="21">
              <a-textarea placeholder="请输入订单备注" v-model="mark" maxlength="200" :rows="3" />
            </a-col>
          </a-row>
        </div>
        <div class="materialShop_order_content">
          <a-row type="flex" justify="space-between">
            <a-col :span="3">
              <div class="materialShop_address_title">订单信息</div>
            </a-col>
          </a-row>
          <div v-for="items in orderProductList" :key="items.supplierId">
            <div class="materialShop_order_supplierName">{{ items.supplierName }}</div>
            <div v-for="item in items.products" :key="item.cartId">
              <a-row>
                <a-col :span="3">
                  <div class="materialShop_car_img">
                    <img :src="getPic(item)" alt />
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="materialShop_car_title">{{ item.productName }}</div>
                  <div class="materialShop_car_label">商品简介：{{ item.introduction }}</div>
                  <div class="materialShop_car_label">规格：{{ item.specifications }}</div>
                  <div class="materialShop_car_label">型号：{{ item.model }}</div>
                </a-col>
                <a-col :span="3">
                  <div class="materialShop_car_text">￥{{ item.price }}</div>
                </a-col>
                <a-col :span="3">
                  <div class="materialShop_car_text">{{ item.num }}</div>
                </a-col>
                <a-col :span="3">
                  <div class="materialShop_car_text" style="color: #ff5555">￥{{ accMul(item.num, item.price) }}</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
        <a-row type="flex" justify="end">
          <a-col :span="3">
            <div class="materialShop_order_text">订单总额：{{ totalPrice }}</div>
          </a-col>
        </a-row>
        <!-- <a-row type="flex" justify="end">
          <a-col :span="3">
            <div class="materialShop_order_text">运费：电话沟通</div>
          </a-col>
        </a-row> -->
        <div class="materialShop_order_bottom">
          <a-row type="flex" justify="end">
            <a-col :span="6">
              <div class="materialShop_order_text">
                应付总额：
                <span style="color: #ff5555; font-size: 20px">￥{{ totalPrice }}</span>
              </div>
            </a-col>
          </a-row>
          <a-row type="flex" justify="end">
            <a-col :span="12">
              <div class="materialShop_order_text">
                配送地址：{{ address.province }}{{ address.city }}{{ address.county }}{{ address.address }}
              </div>
            </a-col>
            <a-col :span="3">
              <div class="materialShop_order_text">收货人：{{ address.consignee }}</div>
            </a-col>
            <a-col :span="4">
              <div class="materialShop_order_text">联系电话：{{ address.phone }}</div>
            </a-col>
          </a-row>
        </div>
        <a-row type="flex" justify="end">
          <a-col :span="3">
            <a-button
              type="primary"
              class="materialShop_order_btn"
              @click="submit"
              :loading="loading"
            >提交订单</a-button
            >
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="tips-content">
      <div class="tips-title">采购步骤：</div>
      <div class="tips-item">①.采购人订单提交：选择商品规格、型号、数量后，提交订单。</div>
      <div class="tips-item">
        ②.供应商响应：供应商收到订单信息，电话联系沟通采购人并沟通最终价格及物流费用后，确认订单。
      </div>
      <div class="tips-item">
        ③.采购人签约：供应商确认订单后，可在控制台-物资采购-订单管理中进行签约合同，确认合同无误后手动签章并确认签署，输入绑定的手机号验证码，合同正式生效。
      </div>
      <div class="tips-item">④.供应商签约：采购人签约合同后，供应商进行签约。</div>
      <div class="tips-item">⑤.采购人打款：供应商签约后，采购人向平台进行线下打款，并上传打款凭证。</div>
      <div class="tips-item">⑥.平台确认打款：采购人打款由平台确认金额无误后，打款至供应商。</div>
      <div class="tips-item">
        ⑦.供应商发货：平台打款至供应商后，供应商可进行发货。发货后，采购人可在物流信息中对应物流单号查看物流信息。
      </div>
      <div class="tips-item">⑧.采购人确认收货：采购人确认收货无误后，完成订单。</div>
      <div class="tips-item">⑨.采购人评论：采购人可对已完成订单进行评价操作。</div>
    </div>
    <a-modal title="订单提交成功" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <div class="modal_text">订单已成功提交，待供应商响应后，可在“控制台—物资采购-订单管理”中进行签约打款。</div>
      <div>
        <router-link
          to="/materialDemand/materialDemandOrder"
          style="margin-left: 175px; font-size: 18px"
        >前往控制台>></router-link
        >
      </div>
    </a-modal>
    <addressForm ref="addressForm" @success="handleGetAddressList" />
  </div>
</template>

<script>
import { addOrder, Addresslist, enterpriseOrderController, employmentMallDeleteAll } from '@/api/materialShop'
import { dateFormat } from '@/common/common'
import moment from 'moment'
import addressForm from '@/views/materialDemand/address/form.vue'

export default {
  name: 'Index',
  components: {
    addressForm
  },
  data () {
    return {
      deliveryTime: '',
      visible: false,
      supplierName: '',
      orderProductList: [],
      addressList: [],
      totalPrice: '',
      addressParams: {
        pageIndex: 1,
        pageSize: 4
      },
      isList: true,

      text: '获取全部地址',
      address: {},
      orderListDtoList: [],
      loading: false,
      mark: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    disabledDate (current) {
      return moment(current).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
    },
    getMore () {
      this.text = '已展示全部地址'
      this.addressParams.pageSize = 100
      this.handleGetAddressList()
    },
    handleGetAddressList () {
      Addresslist(this.addressParams).then((res) => {
        this.addressList = res.data
        console.log(res)
      })
    },
    getAddress (item) {
      this.address = item
    },
    init () {
      this.orderProductList = JSON.parse(this.$route.query.data)
      this.totalPrice = this.$route.query.totalPrice
      this.handleGetAddressList()
    },
    submit () {
      if (!this.address.consignee) {
        this.$message.error('请选择收货地址')
        return
      }
      // if (!this.deliveryTime) {
      //   this.$message.error('请选择收货时间')
      //   return
      // }
      this.loading = true
      const orderListDtoList = this.orderProductList.map((item) => ({
        orderAddCmd: {
          orderType: '1',
          clientId: 'enterprise',
          /** 订单金额 */
          amount: this.totalPrice,
          /** 发货时间 */
          // deliveryTime: dateFormat(this.deliveryTime._d) + ' 00:00:00',
          /** 订单状态; */
          orderStatus: '0',
          /** 收货人 */
          consignee: this.address.consignee,
          /** 收货人电话 */
          phone: this.address.phone,
          shippingAddress: this.address.province + this.address.city + this.address.county + this.address.address,
          supplierId: item.userId || item.supplierId,
          supplierName: item.supplierName,
          remake: this.mark
        },
        orderProductAddCmdList: item.products,
        shoppingCart: item.products.map((item) => item.cartId).filter((item) => !!item)
      }))
      addOrder({ orderListDtoList })
        .then((res) => {
          if (res.success) {
            this.visible = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleOk () {
      this.visible = false
      history.go(-2)
    },
    handleCancel () {
      this.visible = false
      history.go(-2)
    },
    onChange (date) {
      console.log(date, 'change')
    },
    getPic (data) {
      if (this.$route.query.isCar) {
        return data.pic ? data.pic.split(',')[0] : ''
      } else {
        const { specifications, model, productSkuDtoList } = data
        const pic = productSkuDtoList.find((item) => item.specifications === specifications && item.model === model).pic
        return pic ? pic.split(',')[0] : data.pic
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../common/style');
@import url('../search/index');
.tips-content {
  padding: 0 36px;
  padding-bottom: 20px;
  position: relative;
  &::after {
    content: '';
    height: 1px;
    width: 1324px;
    background: #c4c4c4;
    position: absolute;
    top: -30px;
    left: 36px;
  }
  .tips-title {
    font-size: 16px;
    font-weight: 550;
    margin-bottom: 20px;
  }
  .tips-item {
    margin-top: 8px;
  }
}
.text--overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
