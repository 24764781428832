<template>
  <a-modal :title="title" :visible="visible" @cancel="handleCancel">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="consignee" label="收货人" prop="consignee">
        <a-input v-model="form.consignee" placeholder="请输入收货人" />
      </a-form-model-item>
      <a-form-model-item ref="phone" label="收货人电话" prop="phone">
        <a-input v-model="form.phone" placeholder="请输入收货人电话" />
      </a-form-model-item>

      <a-form-model-item label="选择省" prop="province">
        <a-select
          ref="select"
          :options="options"
          @change="changeCity"
          placeholder="请选择"
          v-model="form.province"
          allowClear
        ></a-select>
      </a-form-model-item>
      <a-form-model-item label="选择市" prop="city">
        <a-select
          ref="select"
          :options="cityList"
          @change="changeDistrict"
          placeholder="请选择"
          v-model="form.city"
          allowClear
        ></a-select>
      </a-form-model-item>
      <a-form-model-item label="选择区" prop="county">
        <a-select
          ref="select"
          :options="districtList"
          allowClear
          placeholder="请选择"
          v-model="form.county"
        ></a-select>
      </a-form-model-item>

      <a-form-model-item ref="address" label="收货地址" prop="address">
        <a-input v-model="form.address" placeholder="请输入收货地址" />
      </a-form-model-item>
    </a-form-model>

    <template slot="footer">
      <a-button @click="resetForm">取消</a-button>
      <a-button type="primary" @click="onSubmit" :loading="loading">确定</a-button>
    </template>
  </a-modal>
</template>


<script>
import { addAddress, ModflyAddress, queryAllDistrict, queryAllProvince } from '@/api/materialShop'
import _ from 'lodash'

export default {
  name: 'addressForm',
  data() {
    return {
      visible: false,
      edit: false,
      loading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      form: {
        consignee: '',
        phone: '',
        address: '',
        province: '',
        city: '',
        county: ''
      },
      options: [],
      cityList: [],
      districtList: [],
      rules: {
        consignee: [{ required: true, message: '请填写收货人', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/, message: '手机号格式不正确' }
        ],
        address: [{ required: true, message: '请填写收货地址', trigger: 'blur' }],
        province: [{ required: true, message: '请选择省', trigger: 'change' }],
        city: [{ required: true, message: '请选择市', trigger: 'change' }],
        county: [{ required: true, message: '请选择区', trigger: 'change' }]
      }
    }
  },
  computed: {
    title() {
      return this.edit ? '编辑收货地址' : '新增收货地址'
    }
  },
  created() {
    queryAllProvince().then(res => {
      res.forEach(v => {
        v.value = v.district
        v.label = v.district
      })
      this.options = res
    })
  },
  methods: {
    //获取市
    changeCity(value, option) {
      this.$set(this.form, 'city', undefined)
      this.$set(this.form, 'county', undefined)
      this.cityList = []
      this.districtList = []
      if (value) {
        const { id: pid } = option.data.props
        pid && this.handleGetOption(pid, 'cityList')
      }
    },
    //获取区
    changeDistrict(value, option) {
      this.$set(this.form, 'county', undefined)
      this.districtList = []
      if (value) {
        const { id: pid } = option.data.props
        pid && this.handleGetOption(pid, 'districtList')
      }
    },
    handleGetOption(pid, type) {
      queryAllDistrict({
        pid
      }).then(res => {
        res.forEach(v => {
          v.value = v.district
          v.label = v.district
        })
        this[type] = res
      })
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loading = true
          const fun = this.edit ? ModflyAddress : addAddress
          fun(this.form)
            .then(res => {
              this.$message.success(`${this.title}成功`)
              this.$emit('success')
              this.resetForm()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },

    resetForm() {
      this.$refs.ruleForm.resetFields()
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
    createModel() {
      this.form = {
        clientId: 'enterprise'
      }
      this.edit = false
      this.visible = true
    },
    editModel(model) {
      this.form = _.cloneDeep(model)
      this.edit = true
      this.visible = true
      const { province, city } = model
      const data = this.options.find(item => item.value === province)
      queryAllDistrict({
        pid: data.id
      }).then(res => {
        res.forEach(v => {
          v.value = v.district
          v.label = v.district
        })
        this.cityList = res
        const areaData = this.cityList.find(item => item.value === city)
        this.handleGetOption(areaData.id, 'districtList')
      })
    }
  }
}
</script>